<template>
  <div class="container">
    <div class="section-1">
      <div class="header">
          <svg t="1622282576409" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5675" width="60" height="60"><path d="M508.610298 746.751558 313.57223 941.791673l0-243.802702L313.57223 535.461341l0-178.781826 0-81.269955L313.57223 112.87272c0-26.927279 21.832238-48.752354 48.759517-48.752354l292.558126 0c26.927279 0 48.759517 21.824051 48.759517 48.752354l0 162.535817 0 81.269955 0 178.781826 0 162.52763 0 243.802702L508.610298 746.751558z" p-id="5676" fill="#6ECEF0"></path></svg>
        <h2>简介</h2>
      </div>
      <div class="introduction">
        <img src="../assets/wyz.jpg" alt="吴彦祖" class="avatar">
        <ul class="list">
          <li>广东靓仔</li>
          <li>毕业于孙逸仙魔法学院</li>
          <li>深圳IT民工</li>
          <li>喜欢日漫</li>
          <li>邮箱：761451281@qq.com</li>
        </ul>
      </div>
    </div>

    <div class="section-2">
      <div class="header">
        <svg t="1622282576409" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5675" width="60" height="60"><path d="M508.610298 746.751558 313.57223 941.791673l0-243.802702L313.57223 535.461341l0-178.781826 0-81.269955L313.57223 112.87272c0-26.927279 21.832238-48.752354 48.759517-48.752354l292.558126 0c26.927279 0 48.759517 21.824051 48.759517 48.752354l0 162.535817 0 81.269955 0 178.781826 0 162.52763 0 243.802702L508.610298 746.751558z" p-id="5676" fill="#6ECEF0"></path></svg>
        <h2>职业技能</h2>
      </div>

      <img src="../assets/skill-tree.png" alt="技能树" class="skill">
    </div>

    <div class="section-3">
      <div class="header">
        <svg t="1622282576409" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5675" width="60" height="60"><path d="M508.610298 746.751558 313.57223 941.791673l0-243.802702L313.57223 535.461341l0-178.781826 0-81.269955L313.57223 112.87272c0-26.927279 21.832238-48.752354 48.759517-48.752354l292.558126 0c26.927279 0 48.759517 21.824051 48.759517 48.752354l0 162.535817 0 81.269955 0 178.781826 0 162.52763 0 243.802702L508.610298 746.751558z" p-id="5676" fill="#6ECEF0"></path></svg>
        <h2>个人项目</h2>
      </div>
      <div class="project-list">
        <div v-for="(item, index) in projectList" :key="index" class="project-item">
          <span>{{item.emoji | getEmoji}}</span>
          <a :href="item.href" target="_blank">{{item.title}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      projectList: [
        {
          emoji: 'book',
          title: '前端开发手册',
          href: 'https://zlx01.github.io/fe-handbook/'
        },
        {
          emoji: 'snake',
          title: '使用dlib人脸检测模型进行人脸活体检测：眨眼+张口',
          href: 'https://blog.csdn.net/Lee_01/article/details/89151044'
        },
        {
          emoji: 'sunny',
          title: '努力研发中...',
          href: '#'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
  .container {
    box-sizing: border-box;
    width: 900px;
    padding: 20px;
    margin: 20px auto;

    .header {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
    }

    .section-1 {
      .introduction {
        padding-left: 20px;
        margin-top: 20px;
        display: flex;

        .avatar {
          width: 180px;
          height: 180px;
          transition: transform 0.3s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }
        }

        .list {
          margin-left: 60px;
          font-size: 20px;
          line-height: 36px;
        }
      }
    }
    .section-2 {
      margin-top: 50px;

      .skill {
        padding-left: 20px;
        margin-top: 20px;
        width: 800px;

        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: scale(1.5);
        }
      }
    }
    .section-3 {
      margin-top: 50px;

      .project-list {
        padding-left: 20px;
        margin-top: 20px;

        .project-item {
          height: 40px;
          line-height: 40px;

          a {
            color: #797979;
            margin-left: 15px;
            font-size: 18px;
          }
        }

      }
    }
  }
</style>
